import React, { Fragment } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInfo,
  faHeart,
  faPlusCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons"

import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"
import Donate from "../Donate/Index"

import style from "./MobileDonate.module.scss"

const MobileDonate = () => {
  return (
    <Navbar fixed="bottom" bg="light" className={style.fixedFooterNav}>
      <Nav className={style.nav}>
        <Nav.Link href="/about-us">
          About us
        </Nav.Link>

        <ToggleModal
          toggle={show => {
            return (
              <Fragment>
                {
                  <Nav.Link onClick={show}>
                    <div className={style.donateIconBorder}></div>
                    <span
                      className={`fa-layers fa-fw ${style.donateIconLayer}`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        color="text-light"
                        className="text-light"
                        size="4x"
                        transform="grow-3"
                      />
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className={style.donateIcon}
                        size="3x"
                        transform="right-1"
                      />
                    </span>
                    Donate
                  </Nav.Link>
                }
              </Fragment>
            )
          }}
          content={hide => {
            return (
              <ModalPortal
                hide={hide}
                hideCloseButton={true}
                size="lg"
                className={style.modalContainer}
                dialogClassName={style.modal}
                heading="How would you like to donate?"
              >
                <Fragment>
                  <Donate showMobileFooter={true} hide={hide} />
                </Fragment>
              </ModalPortal>
            )
          }}
        />

        <Nav.Link href="/get-help">
          Get help
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default MobileDonate

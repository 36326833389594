import React, { Fragment } from 'react';
import style from './Loader.module.scss';

export const Loader = ({center}) => (
  <div className={`${style.ldsRing} ${center ? style.center : ''}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export const BtnLoader = () => (
  <Fragment>
    &nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span className="sr-only">Loading...</span>
  </Fragment>
)
import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { createLink } from "../utils"
import style from "./Navbar/NavbarContainer.module.scss"

import { Navbar, Nav, NavDropdown, Button, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { ModalPortal, ToggleModal } from "../portals/Modal.portal"
import Donate from "./Donate/Index"

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    target
    connectedObject {
      __typename
    }
  }

  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }
      allSettings {
        generalSettingsUrl
      }
    }
  }
`

const renderNavLink = (menuItem, location, allSettings) => {
  const currentPageURL = allSettings.generalSettingsUrl + location.pathname

  return (
    <Nav.Link
      key={menuItem.id}
      href={createLink(menuItem.url)}
      className={`${style.navLink} ${menuItem.url === currentPageURL &&
        `${style.activeLink} active`}`}
      dangerouslySetInnerHTML={{ __html: menuItem.label }}
    />
  )
}
const renderDropdown = (menuItem, location, allSettings) => {
  const currentPageURL = allSettings.generalSettingsUrl + location.pathname
  let isActive = false
  menuItem.childItems.nodes.map(item => {
    if (item.url === currentPageURL) {
      isActive = true
    }
  })
  return (
    <NavDropdown
      id={menuItem.id}
      title={menuItem.label}
      key={menuItem.id}
      className={`${style.navLink} ${isActive &&
        `${style.activeLink} active`} ${style.navDropdown} `}
    >
      {menuItem.childItems.nodes.map(item => renderDropdownItem(item))}
    </NavDropdown>
  )
}

const renderDropdownItem = menuItem => {
  return (
    <NavDropdown.Item key={menuItem.id} href={createLink(menuItem.url)}>
      <span className="mr-3" />
      {menuItem.label}
    </NavDropdown.Item>
  )
}

const Menu = ({ location, toggle }) => {
  const data = useStaticQuery(MENU_QUERY)

  const [mobileToggle, setMobileToggle] = toggle

  if (data.wpgraphql.menuItems) {
    return (
      <Fragment>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setMobileToggle(!mobileToggle)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav
            className={`mt-3 mt-lg-4 d-none d-lg-inline-flex ${style.donateContainer}`}
          >
            {!(
              location.pathname === "page-not-found" ||
              location.pathname === "checkout"
            ) && (
              <ToggleModal
                toggle={show => {
                  return (
                    <Button
                      variant="secondary"
                      size="lg"
                      onMouseEnter={show}
                      className="poppins-extrabold"
                    >
                      Donate{" "}
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="xs"
                        className={`ml-1`}
                      />
                    </Button>
                  )
                }}
                content={hide => {
                  return (
                    <ModalPortal
                      hide={hide}
                      size="md"
                      dialogClassName={style.modal}
                      heading="How would you like to donate?"
                    >
                      <Donate />
                    </ModalPortal>
                  )
                }}
              />
            )}
          </Nav>
          <Nav className="ml-auto mt-4 mt-lg-0">
            {data.wpgraphql.menuItems.nodes.map(menuItem => {
              if (menuItem.childItems.nodes.length) {
                return renderDropdown(
                  menuItem,
                  location,
                  data.wpgraphql.allSettings
                )
              } else {
                return renderNavLink(
                  menuItem,
                  location,
                  data.wpgraphql.allSettings
                )
              }
            })}
          </Nav>

        </Navbar.Collapse>
      </Fragment>
    )
  } else {
    return null
  }
}

export default Menu

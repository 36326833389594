import React, { useState, Fragment } from "react"
import Navbar from "react-bootstrap/Navbar"
import style from "./NavbarContainer.module.scss"
import logo from "../../images/lv-logo-v-1-red.svg"
import logoWhite from "../../images/lv-logo-v-1-white.svg"
import mobileLogo from "../../images/lv-logo-tag-red.svg"
import mobileLogoWhite from "../../images/lv-logo-tag-white.svg"
import TopBanner from "../AcfPageBlocks/TopBanner"
import { graphql, useStaticQuery } from "gatsby"
import Menu from "../Menu"
import { useScrollPosition } from "../../utils/useScrollPosition"
import useDeviceDetect from "../../utils/useDeviceDetect"

// how to display correct header type (white or black)

const NavbarContainer = ({ location }) => {
  const BANNER_QUERY = graphql`
    {
      wpgraphql {
        menu(id: "TWVudToy") {
          id
        }
        menus {
          nodes {
            acfTopBanner {
              bannerElements {
                ... on WPGraphQL_Menu_Acftopbanner_BannerElements_TopBanner {
                  bannerItems
                  bannerLink {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const [hideOnScroll, setHideOnScroll] = useState(false)
  const [mobileToggle, setMobileToggle] = useState(false)
  const { isMobile } = useDeviceDetect()
  const isTransparent = false
  const data = useStaticQuery(BANNER_QUERY)

  const bannerElements =
    data.wpgraphql.menus.nodes[2].acfTopBanner.bannerElements[0]

  const bannerItems = bannerElements ? bannerElements.bannerItems : null
  const bannerLink = bannerElements ? bannerElements.bannerLink : null

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y && currPos.y < -80
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow)
      }
    },
    [hideOnScroll],
    false,
    false,
    200
  )

  const renderStandardNavbar = (fixed = false) => {
    const positionCss = fixed
      ? `fixed-top ${style.stickyNavbar} `
      : `${style.nonStickyNavbar} ${bannerItems && style.topBannerPosition}`

    let currentDesktopLogo = ""
    let currentMobileLogo = ""
    let navbarTransparentState = ""

    if (fixed) {
      currentMobileLogo = mobileLogo
      currentDesktopLogo = logo
    } else {
      if (isTransparent && !mobileToggle) {
        currentDesktopLogo = logoWhite
        currentMobileLogo = mobileLogoWhite
        navbarTransparentState = style.navbarTransparent
      } else {
        currentMobileLogo = mobileLogo
        currentDesktopLogo = logo
      }
    }

    return (
      <Fragment>
        {bannerItems && (
          <TopBanner bannerItems={bannerItems} bannerLink={bannerLink} />
        )}
        <Navbar
          bg={
            fixed
              ? `light`
              : isTransparent && !mobileToggle
              ? `transparent`
              : `light`
          }
          className={`justify-content-lg-around w-100 ${positionCss} ${style.navbar} ${isTransparent && !mobileToggle ? navbarTransparentState : ""}`}
          expand="lg"
        >
            <Navbar.Brand href="/"className={style.logoCls} />
          <Menu location={location} toggle={[mobileToggle, setMobileToggle]} />
        </Navbar>
      </Fragment>
    )
  }

  return <Fragment>{renderStandardNavbar(hideOnScroll, location)}</Fragment>
}

NavbarContainer.defaultProps = {}

export default NavbarContainer

import { Link } from "gatsby"
import React from "react"
import { Col } from "react-bootstrap"

import Logo from "../Logo"

import style from "./Footer.module.scss"

const LogoColumn = colProps => {
  return (
    <Col {...colProps}>
      <Link to="/" className={style.navLinkText}>
        <div className={style.logoWrapper}>
          <Logo />
        </div>
      </Link>
    </Col>
  )
}

export default LogoColumn

export const filterPostsByCategories = (allPosts, categories) => {
    if (categories === null || categories === '' || categories === undefined) {
        return allPosts;
    }

    let posts = [];

    let includedCategories = categories.split(',');

    if (includedCategories.length > 0) {
        for (var i = 0; i < includedCategories.length; i++) {
            posts = posts.concat(allPosts.filter(
                (post) => {
                    if (posts.includes(post)) {
                        return false;
                    }
                    return post.categories.nodes.filter( category => category.name.toLowerCase() === includedCategories[i].toLowerCase()).length > 0 && post.postsLayouts.postObject.length > 0
                })
            );
        }

        return posts.sort((a,b)=> new Date(b.date) - new Date(a.date));
    } else return [];
}

export const prioritisePosts = (posts) => {
    let originalPosts = posts;
    if(posts.length > 3) {
        const indexes = posts.map((post,i) => {
            return post.tags.nodes.filter(tag => tag.id === process.env.FEATURED_TAG_ID).length > 0 ? i : undefined
        }).filter(x => x);
        for(var i = 0; i < indexes.length; i++) {
            console.log('i='+i, indexes);
            posts = immutableMove(originalPosts, indexes[i], i);
        }
    }

    return posts;
}

function immutableMove(arr, from, to) {
    return arr.reduce((prev, current, idx, self) => {
        if (from === to) {
            prev.push(current);
        }
        if (idx === from) {
            return prev;
        }
        if (from < to) {
            prev.push(current);
        }
        if (idx === to) {
            prev.push(self[from]);
        }
        if (from > to) {
            prev.push(current);
        }
        return prev;
    }, []);
}

import React from "react"
import { Row, Container } from "react-bootstrap"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

import style from "./Footer.module.scss"
import MobileDonate from "./MobileDonate"
import LogoColumn from "./LogoColumn"
import NavColumn from "./NavColumn"
import FormColumn from "./FormColumn"
import BottomRow from "./BottomRow"

config.autoAddCss = false

const Footer = () => {
  return (
    <div className="w-100">
      <MobileDonate />
      <footer className={style.footer}>
        <Container
          fluid="xxl"
          // px value in both containers mirrors top navbar
          className="py-5 px-4 px-xl-7 px-xxl-0 text-secondary bg-light"
        >
          <Row>
            <LogoColumn sm={12} lg={2} />
            <NavColumn sm={12} lg={5} className="pl-xl-7" />
            <FormColumn sm={12} lg={5} />
          </Row>
        </Container>

        <div className="bg-primary">
          <Container
            fluid="xxl"
             // pb offsets fixed MobileDonate
            className="pt-4 pb-12 py-lg-4 px-4 px-xl-7 px-xxl-0"
          >
            <BottomRow />
          </Container>
        </div>
      </footer>
    </div>
  )
}

export default Footer


import style from "./ProductItems.module.scss";
import React, { Fragment, useState } from "react"
import { basketActions, basketSelectors } from "../state/basket";
import { connect } from "react-redux";
import { useScrollPosition } from '../utils/useScrollPosition';
import cart from "../images/icon-cart.png";


const Cart = ({ productItems, items, addItemToBasket, plusItemCount, minusItemCount, basket, total, totalPrice, cartBar, position }) => {

  const [hideOnScroll, setHideOnScroll] = useState(false);
  const [isPositionTop, setIsPositionTop] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {

      let isShow = currPos.y > prevPos.y && currPos.y < 190;

      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }

      (currPos.y == 0) ? setIsPositionTop(true) : setIsPositionTop(false);
    },
    [hideOnScroll], false, false, 200
  );



  const renderStandardProductItems = (fixed = false) => {

    let stickyCss = fixed ? `${style.stickyNavbar}` : `${style.nonStickyNavbar}`;
    let topCss = isPositionTop ? `${style.cartNavTop}` : ``;

    if (total > 0) {

      return (
        <div>
          <div className={`${style.cartNav} ${stickyCss} ${topCss}`} >
            { cartBar &&
              <div className={`mx-auto position-relative ${style.textDiv}`}>
                <p className="text-white font-weight-bold mb-0">{total} Donation
                    have been added to your basket. <a href="/basket" className="text-white">Go to checkout.</a></p>
              </div>
            }
            { cartBar ?
                <div className="mr-0 mr-lg-4 mt-2 mt-lg-0 d-flex">
                  <div className={`d-flex align-items-center`}>
                    <span className="text-white mr-2 font-weight-bold">£{totalPrice.toFixed(2)} Basket</span>
                    <a href="/basket">
                      <div className={`d-flex position-relative`}>
                        <img className={style.cartImg} src={cart} alt="cart" />
                        <p className={style.countNumber}>{total}</p>
                      </div>
                    </a>
                  </div>
              </div>
              :
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <span className="text-white mr-2 font-weight-bold">£{totalPrice.toFixed(2)} Basket</span>
                  <a href="/basket">
                    <div className={`d-flex position-relative`}>
                      <img className={style.cartImg} src={cart} alt="cart" />
                      <p className={style.countNumber}>{total}</p>
                    </div>
                  </a>
                </div>
             }


          </div>
        </div>
      )
    }
  }

  return (
    <Fragment>
      {renderStandardProductItems(hideOnScroll)}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  items: basketSelectors.getItemsIdAndCount(state),
  basket: basketSelectors.getItems(state),
  total: basketSelectors.getTotalItems(state),
  totalPrice: basketSelectors.getTotalItemsValue(state)
});

const mapDispatchToProps = {
  addItemToBasket: basketActions.addItemToBasket,
  plusItemCount: basketActions.plusItemCount,
  minusItemCount: basketActions.minusItemCount,
  setItemCount: basketActions.setItemCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

export { filterPostsByCategories, prioritisePosts } from "./postsHelper"

export const createLink = url => {

if (!url || url === "#") {
  return "#"
}

  const localSiteUrl = new RegExp(
    `^(${process.env.SITE_URL_PROD}|${process.env.SITE_URL_STAG})`
  )

  if (url.match(localSiteUrl)) {
    return url.replace(localSiteUrl, "").replace("/home/", "/")
  }

  return url
}

import React from "react"
import style from "./TopBanner.module.scss"
import { createLink } from "../../utils/"

const TopBanner = ({ bannerItems, bannerLink }) => {
  if (bannerItems)
    return (
      <div className={`${style.topBanner}`}>
        {bannerItems && <p className="m-0">{bannerItems}</p>}
        {bannerLink && bannerLink.url && (
          <a href={createLink(bannerLink.url)}>{bannerLink.title}</a>
        )}
      </div>
    )
  return null
}

export default TopBanner

import React, { useState } from "react"
import axios from "axios"
import { Button, Col, Form, Row } from "react-bootstrap"
import queryString from "query-string"
import "@fortawesome/fontawesome-svg-core/styles.css"

import { BtnLoader } from "../UI/Loader"
import round from "../../images/round.png"

import style from "./Footer.module.scss"

const FormColumn = colProps => {
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({
    initial: true,
  })

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()

    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    const emailData = {
      firstName: form.elements.firstName.value,
      lastName: form.elements.lastName.value,
      email: form.elements.email.value,
    }
    const postForm = async () => {
      try {
        setIsSubmitting(true)
        const response = await axios.post(
          `${process.env.NEWSLETTER_SIGNUP_WORDPRESS_FORM_URL}`,
          queryString.stringify(emailData)
        )

        setStatus({
          success: "Thank you for subscribing for updates from Little Village",
          initial: false,
        })
      } catch (exception) {
        setIsSubmitting(false)
        console.error(exception)
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`,
        })
      }
    }
    postForm()
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }
  }

  return (
    <Col {...colProps}>
      <div className="d-flex flex-column w-100 pt-md-4">
        {status && status.success ? (
          <>
            <h3 className="h4 mb-4">Thank you!</h3>
            <p className="lead">{status.success}</p>
          </>
        ) : (
          <div className="w-100">
            <h3 className="h4 mb-4 text-left">Subscribe for updates</h3>
            <Form
              id="contactForm"
              action="#"
              method="post"
              name="mc-embedded-subscribe-form"
              target="_blank"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col xs={12} sm={6} lg={12} className="col-xxl-6">
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      className={style.formInput}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} lg={12} className="col-xxl-6">
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      className={style.formInput}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group controlId="email">
                    <Form.Label>E-mail address</Form.Label>
                    <div className={style.emailInputWrapper}>
                      <Form.Control
                        type="email"
                        name="email"
                        className={style.emailInput}
                        required
                      />
                      <Button
                        variant="link"
                        type="submit"
                        className={style.submitButton}
                      >
                        <span className="d-flex align-items-center">
                          Submit
                          <span className={style.submitButtonIcon} />
                        </span>
                        {isSubmitting && <BtnLoader />}
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    </Col>
  )
}

export default FormColumn

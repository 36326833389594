
import React from 'react'


const CustomJavascript = () => {

return(
    <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11348529657" />
        <script type="text/javascript" dangerouslySetInnerHTML={{
            __html: `
            var _qevents = _qevents || [];
            (function() {
            var elem = document.createElement('script');
            elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            elem.async = true;
            elem.type = "text/javascript";
            var scpt = document.getElementsByTagName('script')[0];
            scpt.parentNode.insertBefore(elem, scpt);
          })();
            _qevents.push({
            qacct:"p-LR3Mw3M5XgW_b",
            uid:"ross@bravand.com"
          });
      
      <!-- Quantcast Choice. Consent Manager Tag v2.0 (for TCF 2.0) -->
          (function() {
          var host = window.location.hostname;
          var element = document.createElement('script');
          var firstScript = document.getElementsByTagName('script')[0];
          var url = 'https://quantcast.mgr.consensu.org'
          .concat('/choice/', 'LR3Mw3M5XgW_b', '/', host, '/choice.js')
          var uspTries = 0;
          var uspTriesLimit = 3;
          element.async = true;
          element.type = 'text/javascript';
          element.src = url;
          firstScript.parentNode.insertBefore(element, firstScript);
          function makeStub() {
          var TCF_LOCATOR_NAME = '__tcfapiLocator';
          var queue = [];
          var win = window;
          var cmpFrame;
          function addFrame() {
          var doc = win.document;
          var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);
          if (!otherCMP) {
          if (doc.body) {
          var iframe = doc.createElement('iframe');
          iframe.style.cssText = 'display:none';
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
      } else {
          setTimeout(addFrame, 5);
      }
      }
          return !otherCMP;
      }
          function tcfAPIHandler() {
          var gdprApplies;
          var args = arguments;
          if (!args.length) {
          return queue;
      } else if (args[0] === 'setGdprApplies') {
          if (
          args.length > 3 &&
          args[2] === 2 &&
          typeof args[3] === 'boolean'
          ) {
          gdprApplies = args[3];
          if (typeof args[2] === 'function') {
          args[2]('set', true);
      }
      }
      } else if (args[0] === 'ping') {
          var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: 'stub'
      };
          if (typeof args[2] === 'function') {
          args[2](retr);
      }
      } else {
          queue.push(args);
      }
      }
          function postMessageEventHandler(event) {
          var msgIsString = typeof event.data === 'string';
          var json = {};
          try {
          if (msgIsString) {
          json = JSON.parse(event.data);
      } else {
          json = event.data;
      }
      } catch (ignore) {}
          var payload = json.__tcfapiCall;
          if (payload) {
          window.__tcfapi(
          payload.command,
          payload.version,
          function(retValue, success) {
          var returnMsg = {
          __tcfapiReturn: {
          returnValue: retValue,
          success: success,
          callId: payload.callId
      }
      };
          if (msgIsString) {
          returnMsg = JSON.stringify(returnMsg);
      }
          event.source.postMessage(returnMsg, '*');
      },
          payload.parameter
          );
      }
      }
          while (win) {
          try {
          if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
      }
      } catch (ignore) {}
          if (win === window.top) {
          break;
      }
          win = win.parent;
      }
          if (!cmpFrame) {
          addFrame();
          win.__tcfapi = tcfAPIHandler;
          win.addEventListener('message', postMessageEventHandler, false);
      }
      };
          makeStub();
          var uspStubFunction = function() {
          var arg = arguments;
          if (typeof window.__uspapi !== uspStubFunction) {
          setTimeout(function() {
          if (typeof window.__uspapi !== 'undefined') {
          window.__uspapi.apply(window.__uspapi, arg);
      }
      }, 500);
      }
      };
          var checkIfUspIsReady = function() {
          uspTries++;
          if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
          console.warn('USP is not accessible');
      } else {
          clearInterval(uspInterval);
      }
      };
          if (typeof window.__uspapi === 'undefined') {
          window.__uspapi = uspStubFunction;
          var uspInterval = setInterval(checkIfUspIsReady, 6000);
      }
      })();
      <!-- End Quantcast Choice. Consent Manager Tag v2.0 (for TCF 2.0) -->
      
      <!-- Meta Pixel Code -->
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '211384525000764');
      fbq('track', 'PageView');
      
      window.dataLayer = window.dataLayer || []; 
      function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); 
        gtag('config', 'AW-11348529657');
        gtag('config', 'AW-11336583744');
      `
        }}/>
    </>
)
}


export default CustomJavascript;

import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";


var MODAL_OPEN = true;
const portalRoot = typeof document !== `undefined` ? document.getElementById('portal-root') : null



export const ModalPortal = ({ hide, heading, hideCloseButton, children, ...props }) => {

  return (

    ReactDOM.createPortal(
      <>
      <div className="modal-blur-container"></div>
      <Modal show={MODAL_OPEN} onHide={hide} {...props}>
        {
          hideCloseButton ?
            <Modal.Header>
              {heading &&
                <Modal.Title>{heading}</Modal.Title>
              }
            </Modal.Header>
            :
            <Modal.Header closeButton>
              {heading &&
                <Modal.Title>{heading}</Modal.Title>
              }
            </Modal.Header>
        }


        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
      </>

      ,
      portalRoot
    )
  )
}


export const ToggleModal = ({ toggle, content, onHide }) => {

  const [isShown, setIsShown] = React.useState(false);
  const hide = () => {

    onHide && onHide();
    setIsShown(false);

  }
  const show = () => setIsShown(true);

  return (
    <React.Fragment>
      {toggle(show)}
      {isShown && content(hide)}
    </React.Fragment>
  );
}

function refreshPage() {
  window.location.reload(false);
}
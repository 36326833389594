import React from 'react'
import Helmet from 'react-helmet'
import Navbar from './Navbar/NavbarContainer'
import { Footer } from "./Footer"
import style from './Layout.module.scss';
import CustomJavascript from './CustomJavascript'
import {connect} from 'react-redux';
import  Cart from './CartInfo'
import he from 'he';
import { basketActions, basketSelectors } from "../state/basket"

const TemplateWrapper = ({ children, seoComponent, defaultMenuBackgroundColour, location , total, canonicalPath}) => {
    let title, description;
    if (seoComponent && seoComponent.seoTitle) {
        title = he.decode(seoComponent.seoTitle)
    } else {
        console.log('title not set', location);
        title =''
    }

    if (seoComponent && seoComponent.description) {
        description = he.decode(seoComponent.description)
    }

    const canonicalUrl = canonicalPath && `${process.env.SITE_URL}${canonicalPath}`

    const cartBar = (!(location.pathname === 'basket' || location.pathname === 'checkout'));

    return (
        <div>
            <Helmet encodeSpecialCharacters={true} title={`${title} - Little Village`} >
                {description &&<meta name="description" content={description} />}
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}                
            </Helmet>
            <Navbar defaultMenuBackgroundColour={defaultMenuBackgroundColour} location={location} />
            <Cart cartBar={cartBar}  />
            <div className={total > 0 ? style.contentWithCart : style.content}>{children}</div>
            <Footer />
            <CustomJavascript />
        </div>
    )
}

const mapStateToProps = state => ({
  total: basketSelectors.getTotalItems(state),
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps) (TemplateWrapper);


import React from "react"
import { Row, Col } from "react-bootstrap"

import style from "./BottomRow.scss"
import linkedin from "../../images/social/linkedin.png"
import ig from "../../images/social/ig.png"
import fb from "../../images/social/facebook.png"
import twitter from "../../images/social/twitter.png"
import fundraiser from "../../images/fr-logo.png"
import babyBank from "../../images/BBA-Membership_badge-Off_white.png"

const socials = [
  {
    url: "https://www.facebook.com/littlevillagehq/",
    name: "facebook",
    img: fb,
  },
  {
    url: "https://www.instagram.com/littlevillagehq/",
    name: "instagram",
    img: ig,
  },
  {
    url: "https://twitter.com/LittleVillageHQ",
    name: "twitter",
    img: twitter,
  },
  {
    url: "https://www.linkedin.com/company/little-village/",
    name: "linkedin",
    img: linkedin,
  },
]

const SocialLinks = () => (
  <div className="d-flex my-3" style={{ gap: "1.5rem" }}>
    {socials.map((social, i) => (
      <a
        key={social.name + "-social-link"}
        href={social.url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-decoration-none"
      >
        <img src={social.img} alt={social.name} width={32} height={32} />
      </a>
    ))}
  </div>
)

const BottomRow = () => {
  return (
    <Row>
      <Col>
        <SocialLinks />

        <small className="text-light mb-lg-0 mb-3">
          Charity number: 1169735 C {new Date().getFullYear()} Little Village.
          All Rights Reserved | Built by{" "}
          <a className="text-light" href="https://www.bravand.com/">
            Bravand
          </a>
        </small>
      </Col>

      <Col className={`d-flex justify-content-end flex-lg-row flex-column col ${style.containerLogo}`}>
        {/* pr-lg-11 offsets FAB */}
        <div className="d-flex justify-content-sm-end align-items-center pt-3 pt-md-0 pr-lg-12 px-xxl-0 mr-lg-3">
          <img
            src={fundraiser}
            alt="fundraising-regulator"
            width={235}
            height={72}
          />
        </div>
        <div className="d-flex justify-content-sm-end align-items-center pt-3 pt-md-0 pr-lg-12 px-xxl-0">
	  <a 
	     className="text-decoration-none" 
	     href="https://babybankalliance.org/"
	  >
          <img
            src={babyBank}
            alt="Baby Bank Logo"
            style={{ objectFit: "contain" }}
            height={72}
          />
	  </a>
        </div>
      </Col>
    </Row>
  )
}

export default BottomRow

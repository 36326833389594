import { faCircle, faHeart, faInfo, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react'
import { Button, Col, Nav, Navbar, Row } from 'react-bootstrap';
import style from '../Footer/Footer.module.scss';
import { graphql, Link, useStaticQuery } from "gatsby"
import { createLink } from "../../utils"

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    target
    connectedObject {
      __typename
    }
  }

   query GET_MENUDonate_ITEMS {
    wpgraphql {
    menuItems(where: {location: SOCIAL}) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }
      subMenuItems: menuItems(where: { location: EXPANDED }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }

      allSettings {
        generalSettingsUrl
      }
    }
  }

`

const Donate = ({ showMobileFooter, hide }) => {
    const data = useStaticQuery(MENU_QUERY);
    const menuItems = data.wpgraphql.menuItems.nodes;
    const subMenuItems = data.wpgraphql.subMenuItems.nodes;

    return (
      <Fragment>
        <div className={`${showMobileFooter ? "text-center" : "text-right"}`}>
          {menuItems.map((menuItem, i) => (
            <div key={`link_${i}`}>
              <Button
                className={`${style.donateLink} text-decoration-none poppins-extrabold text-white`}
                variant="link"
                size="lg"
                href={createLink(menuItem.url)}
              >
                {menuItem.label} {">"}
              </Button>
            </div>
          ))}
        </div>
        {showMobileFooter && (
          <div className="text-center m-auto">
            <Button
              onClick={hide}
              className={`$text-decoration-none text-white`}
              variant="link"
              size="lg"
            >
              <FontAwesomeIcon icon={faTimesCircle} className={``} size="2x" />
            </Button>
            <p className="text-light poppins-medium">
              How would you like to donate?
            </p>
          </div>
        )}
      </Fragment>
    )
}

export default Donate;
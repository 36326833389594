import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Row, Col } from "react-bootstrap"

import { createLink } from "../../utils"

import style from "./Footer.module.scss"

const FOOTER_MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    target
    connectedObject {
      __typename
    }
  }

  query GET_MENUFooter_ITEMS {
    wpgraphql {
      menuItems(where: { location: FOOTER }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }
      subMenuItems: menuItems(where: { location: EXPANDED }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }

      allSettings {
        generalSettingsUrl
      }
    }
  }
`

const NavColumn = colProps => {
  const data = useStaticQuery(FOOTER_MENU_QUERY)
  const menuItems = data?.wpgraphql?.menuItems?.nodes

  return (
    <Col {...colProps}>
      <div className="d-flex flex-column w-100 pb-4 pb-lg-0 pt-md-4 bg-md-primary">
        <h3 className="h4 mb-4 text-left">Quick links</h3>
        <ul className={style.navItemList}>
          {menuItems.map((menuItem, i) => (
            <li className="pb-1">
              <Link
                key={"footer-menu-item-" + menuItem.id}
                to={createLink(menuItem.url)}
                className={style.navLinkText}
              >
                {menuItem.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Col>
  )
}

export default NavColumn
